import React, {useState} from 'react';
import './playButton.css';
import Modal from './Modal';

function PlayButton({ service }) {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  }

  return (
    <>
      <div className={`trailer d-flex align-items-center justify-content-center ${service.active ? 'active' : undefined}`}>
          <button className='playBtn' onClick={toggleModal}>
            <span className="playBtn-icon">
              <span></span>
            </span>
            {/* <i className="fa-solid fa-play"></i> */}
          </button>
          {/* <p>Watch Trailer</p> */}
      </div>
      {
        service.active && (
          <div className="modal-wrapper">
            <Modal service={service} status={modal} toggleModal={toggleModal} />
          </div>
        )
      }
    </>
  )
}

export default PlayButton