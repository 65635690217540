import React, { useState } from 'react';
import './header.css';
import logo from '../../images/logo-jgolf.png';
import NavListItem from './NavListItem';
import navListData from '../../data/navListData';
import ReservationButton from '../../components/buttons/ReservationButton';

function Header({ scroll }) {

    const [navList, setNavList] = useState(navListData);
    const [toogleMenuOpen, setToogleMenuOpen] = useState(false);

    const handleNavOnClick = id => {
        const newNavList = navList.map(nav => {
            nav.active = false;
            if (nav._id === id) nav.active = true;
            return nav;
        });

        setNavList(newNavList);
    };

    const handleToogleMenu = () => {
        setToogleMenuOpen(!toogleMenuOpen);
    };

    return (
        <header className={`${scroll>100 ? 'scrolled' : undefined}`}>
            <div className={`navBar ${scroll>100 ? 'scrolled' : undefined}`}>
                <div className="navBarWrapper">
                    <div className="navBarLogo">
                        <a href='/' className="navBarMenuLink">
                            <img className="logoImg" src={logo} alt='Logo' />
                        </a>
                    </div>
                    <div className="navBarMenu">
                        <ul className='navBarMenuList'>
                            {
                                navListData.map(nav => (
                                    <NavListItem key={nav._id} nav={nav} navOnClick={handleNavOnClick} />
                                ))
                            }
                        </ul>
                    </div>
                   
                    <ReservationButton icon={<i className="fa-regular fa-calendar-days"></i>} name="Réserver" />
                    {/* <Button icon={<i className="fa-solid fa-right-to-bracket"></i>} name="Sign in" /> */}

                    {/* Mobile Menu & Hamberger Icon */}
                    <div className="navBarMobileMenu">
                        <div className="navBarMobileMenuToogle" onClick={handleToogleMenu}>
                            {toogleMenuOpen ? (
                                <i className="fas fa-times fa-2x"></i>
                            ) : (
                                <i className="fas fa-bars fa-2x"></i>
                            )}
                        </div>
                        {/* Mobile Menu Items */}
                        <div className={`navBarMobileMenuItems ${toogleMenuOpen ? 'active' : ''}`}>
                            {/* <ul className="navBarMobileMenuList"> */}
                            <ul className={`navBarMobileMenuList ${toogleMenuOpen ? 'active' : ''}`}>
                                {/* <li className="navBarMobileMenuItem">
                                    <a href="#" className="navBarMobileMenuLink">
                                        Activité et Services
                                    </a>
                                </li> */}
                                {
                                    navListData.map(nav => (
                                        <div className="navBarMobileMenuLink">
                                            <NavListItem key={nav._id} nav={nav} navOnClick={handleNavOnClick} />
                                        </div>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header