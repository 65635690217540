import React, {useState, useEffect} from 'react';
import './homeServices.css';
import ServiceCard from '../../components/cards/ServiceCard';

function HomeServices() {
    const initialFilterList = [
        { _id: 1, name: 'TOUS', active: true },
        { _id: 2, name: 'ÉCONOMIE', active: false },
        { _id: 3, name: 'PRIME', active: false },
        { _id: 4, name: 'VIP', active: false },
    ];

    const [data, setData] = useState([]);
    const [services, setServices] = useState([]);
    const [filters, setFilters] = useState(initialFilterList);

    const dataServicesUrl = process.env.REACT_APP_DATA_SERVICES_URL;

    // const [data, setData] = useState([]);
    // const [services, setServices] = useState([]);
    // const [filters, setFilters] = useState(filterList);

    // const dataServicesUrl = process.env.REACT_APP_DATA_SERVICES_URL;
    // const fetchData = () => {
    //     // fetch('http://localhost:3000/data/servicesData.json')
    //     fetch(dataServicesUrl)
    //     .then(res => res.json())
    //     .then(data => setData(data))
    //     .catch(e => console.log(e.message));
    // };
    
    // useEffect(() => {
    //     fetchData()
    // }, []);

    // useEffect(() => {
    //     setServices(data);
    // }, [data]);

    // const fetchData = async () => {
    //     try {
    //         // const response = await fetch('http://localhost:3000/data/servicesData.json');
    //         const response = await fetch(dataServicesUrl);
    //         const result = await response.json();
    //         setData(result);
    //     } catch (error) {
    //         console.log(error.message);
    //     }
    // };

    // const fetchData = async () => {
    //     try {
    //         const response = await fetch(dataServicesUrl);
    
    //         // Check if the response is JSON
    //         if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    //         const contentType = response.headers.get("content-type");
    //         if (contentType && contentType.includes("application/json")) {
    //             const result = await response.json();
    //             setData(result);
    //         } else {
    //             throw new Error("Received non-JSON response");
    //         }
    //     } catch (error) {
    //         console.error("Fetch error:", error.message);
    //     }
    // };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(dataServicesUrl);
                const contentType = response.headers.get("content-type");
                
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    
                if (contentType && contentType.includes("application/json")) {
                    const result = await response.json();
                    setData(result);
                } else {
                    throw new Error("Received non-JSON response");
                }
            } catch (error) {
                console.error("Fetch error:", error.message);
            }
        };
    
        fetchData();
        
    }, [dataServicesUrl]);
    
    useEffect(() => {
        setServices(data);
    }, [data]);

    // const handleFilterServices = category => {
    //     const filterActiveBtn = filters.map(filter => {
    //         filter.active = false;
    //         if (filter.name === category) {
    //             filter.active = true;
    //         }
    //         return filter;
    //     })
    //     if (category === 'All') {
    //         setServices(data);
    //         return;
    //     }
    //     setFilters(filterActiveBtn);

    //     const filterServices = data.filter(service => service.category === category);
    //     setServices(filterServices);
    // }

    const handleFilterServices = (category) => {
        const updatedFilters = filters.map(filter => ({
            ...filter,
            active: filter.name === category,
        }));
        setFilters(updatedFilters);

        if (category === 'TOUS') {
            setServices(data);
        } else {
            const filteredServices = data.filter(service => service.category === category);
            setServices(filteredServices);
        }
    };

    // return (
    //     <section id="services" className="services">
    //         <div className="container-fluid">
    //             <div className="row">
    //                 <h4 className="section-title">Activités et Services</h4>
    //             </div>
    //             <div className="row">
    //                 <ul className="filters">
    //                     {
    //                         filters.map(filter => (
    //                             <li 
    //                                 key={filter._id} 
    //                                 className={`${filter.active ? 'active' : undefined}`}
    //                                 onClick={() => {handleFilterServices(filter.name)}}
    //                             >
    //                                 {filter.name}
    //                             </li>
    //                         ))
    //                     }
    //                 </ul>
    //             </div>
    //             <div className="row mt-5">
    //                 {
    //                     services && services.length > 0 && services.map(service => (
    //                         <Card key={service._id} service={service} />
    //                     ))
    //                 }
    //             </div>
    //         </div>
    //     </section>
    // );

    return (
        <section id="services" className="services">
            <div className="container-fluid">
                <div className="row">
                    <h4 className="section-title">Tarif <small>&</small> Forfait</h4>
                </div>
                <div className="row">
                    <ul className="filters">
                        {filters.map(filter => (
                            <li 
                                key={filter._id} 
                                className={filter.active ? 'active' : ''}
                                onClick={() => handleFilterServices(filter.name)}
                            >
                                {filter.name}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="row mt-5">
                    {services && services.length > 0 ? (
                        services.map(service => (
                            <ServiceCard key={service._id} service={service} />
                        ))
                    ) : (
                        <p>No services available</p>
                    )}
                </div>
            </div>
        </section>
    );
}

export default HomeServices