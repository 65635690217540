import React from 'react';
import './googleMap.css';

function GoogleMap() {
  return (
        <div className="map">
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5463.783529849812!2d-71.28487248821958!3d46.78673707100598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb896dc3464a25f%3A0xb8e9aa4d9feccdd7!2sPyramide%20de%20Sainte-Foy!5e0!3m2!1sen!2sca!4v1728789294933!5m2!1sen!2sca"
                title="Google Map"
                allowFullScreen 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
        // <div>
        //     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2731.891764924905!2d-71.28487318821945!3d46.786737071005994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb896ddc8f230c3%3A0x67753506a8dd581f!2sOngles%20Jessica!5e0!3m2!1sen!2sca!4v1728788518610!5m2!1sen!2sca" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        //  </div>
    )
}

export default GoogleMap