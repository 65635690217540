import React, {useState, useEffect} from 'react';
import './homeFaqs.css';
import FaqAccordion from '../../components/accordions/FaqAccordion';

function HomeFaqs() {
    const initialFilterList = [
        { _id: 1, name: 'Tous', active: true },
        { _id: 2, name: 'Général', active: false },
        { _id: 3, name: 'Technologie', active: false },
    ];

    const [data, setData] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [filters, setFilters] = useState(initialFilterList);
    const [showFaqs, setShowFaqs] = useState(5);

    const dataFaqsUrl = process.env.REACT_APP_DATA_FAQS_URL;

    const fetchData = async () => {
        try {
            const response = await fetch(dataFaqsUrl);
            if (!response.ok) throw new Error('Failed to fetch FAQs');
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        fetchData()
    }, []);
    
    useEffect(() => {
        setFaqs(data);
    }, [data]);

    // const handleFilterFaqs = (category) => {
    //     const updatedFilters = filters.map(filter => ({
    //         ...filter,
    //         active: filter.name === category,
    //     }));
    //     setFilters(updatedFilters);

    //     if (category === 'All') {
    //         setFaqs(data);
    //     } else {
    //         const filteredFaqs = data.filter(faq => faq.category === category);
    //         setFaqs(filteredFaqs);
    //     }
    // };

    const handleFilterFaqs = (category) => {
        setFilters(filters.map(filter => ({
            ...filter,
            active: filter.name === category,
        })));

        setFaqs(category === 'Tous' ? data : data.filter(faq => faq.category === category));
    };

    const showMoreFaqs = () => {
        setShowFaqs(prevValue => prevValue + 5);
    };

    const showLessFaqs = () => {
        setShowFaqs(prevValue => prevValue - 5);
    };

    return (
        <section id="faqs" className="faqs">
            <div className="container-fluid">
                <div className="row">
                    <h4 className="section-title">Foire aux questions</h4>
                </div>
                <div className="row">
                    <ul className="filters">
                        {filters.map(filter => (
                            <li 
                                key={filter._id} 
                                className={filter.active ? 'active' : ''}
                                onClick={() => handleFilterFaqs(filter.name)}
                            >
                                {filter.name}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="row mt-5">
                    {faqs && faqs.length > 0 ? (
                        faqs.slice(0, showFaqs).map(faq => (
                            <div className="pb-3">
                                <FaqAccordion key={faq._id} faq={faq} />
                            </div>
                        ))
                    ) : (
                        <p>Aucune information disponible.</p>
                    )}
                </div>
                <div className="row">
                    {
                        faqs && faqs.length > showFaqs ? (
                            <div className="accordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header text-center">
                                        <button
                                            className="accordion-button collapsed bg-default"
                                            type="button"
                                            onClick={showMoreFaqs}
                                        >
                                            <h4 className="text-primary">Afficher plus. . .</h4>
                                        </button>
                                    </h2>
                                </div>
                            </div>
                        ) : (
                            <div className="accordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header text-center">
                                    <button
                                        className="accordion-button bg-default"
                                        type="button"
                                        onClick={showLessFaqs}
                                    >
                                        <h4 className="text-primary">Afficher moins . . .</h4>
                                    </button>
                                </h2>
                            </div>
                        </div>
                        )
                    }
                  
                </div>
            </div>
        </section>
    );
}

export default HomeFaqs