import React from 'react';
import './faqAccordion.css';

function FaqAccordion({ faq }) {
    return (
        <div className="accordion" id="accordionFaq">
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button 
                        className="accordion-button collapsed bg-dark-subtle" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target={`#faq-${faq._id}`} 
                        aria-expanded="false" 
                        aria-controls="flush-collapseOne"
                    >
                        <h4>{faq.question}</h4>
                    </button>
                </h2>
                <div 
                    id={`faq-${faq._id}`} 
                    className="accordion-collapse collapse" 
                    data-bs-parent="#accordionFaq"
                >
                    <div 
                        className="accordion-body"
                    >
                        <h5>{faq.answer}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqAccordion