import React from 'react';
import './modal.css';

function Modal({ service, status, toggleModal }) {
    return (
        <div className={`serviceModalWrapper ${status ? 'active' : ''}`}>
            <div className="serviceModalContent">
                <span className="modalClose" onClick={toggleModal} role="button" aria-label="Close modal">
                    &times;
                    {/* <i className="fa-solid fa-xmark"></i> */}
                </span>
                {service?.video && (
                    <iframe
                        id="videoPlayer"
                        width="1280"
                        height="720"
                        src={service.video}
                        title={`${service.title} | Official Trailer`}
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                )}
            </div>
        </div>
    )
}

export default Modal;