import React from 'react';
import './footerNavItem.css';

function FooterNavItem({ name }) {
    return (
        <li>
            <i className="fa-solid fa-chevron-right"></i>{' '}
            <a href="#">{name}</a>
        </li>
    );
}

export default FooterNavItem