import React, {useState, useEffect} from 'react';
import './homeContact.css';
import GoogleMap from '../../components/GoogleMap';

function HomeContact() {
    return (
        <section id="contact" className="contact">
            <div className="container-fluid">
                <div className="row">
                    <h4 className="section-title">Nous Joindre</h4>
                </div>
                <div className="row mt-5">
                    <GoogleMap />
                </div>
            </div>
        </section>
    );
}

export default HomeContact