import React from 'react';
import './serviceCard.css';
import { COLOR_BASIC, COLOR_PRO, COLOR_VIP } from '../../utils/colorCode';

function ServiceCard({ service }) {
    return (
        <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card-wrapper py-3">
                {/* <div className={`${service.category === "VIP" ? "card-vip" : service.caterory === "PRO" ? "card-pro" : "card"}`}> */}
                {/* <div 
                    className="card" 
                    style={`${service.category === "VIP" ? {"--clr": "#249eff"} : service.caterory === "PRO" ? {"--clr": "ff1f6b"} : {"--clr": "#98ff21"}}`}
                > */}
                <div 
                    className="card" 
                    style={{"--card--color": service.category === "VIP" ? COLOR_VIP : service.category === "PRIME" ? COLOR_PRO : COLOR_BASIC}}
                >
                    <div className="box">
                        <div className="icon">
                            <div className="iconBox">
                                <h4>{service.title}</h4>
                            </div>
                        </div>
                        <div className="content">
                            <h3 className="price">{service.price}</h3>
                            <p>{service.description}</p>
                            <a href="#">Réserver</a>
                        </div>
                        <div className="card-note">
                            <h5>{service.appliedDate}</h5>
                        </div>
                    </div>
                </div>
                {/* <div className="card" style={{ "--clr": "#ff1f6b" }}>
                    <div className="box">
                        <div className="icon">
                            <div className="iconBox">

                            </div>
                            <div className="content">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="card" style={{ "--clr": "#98ff21" }}>
                    <div className="box">
                        <div className="icon">
                            <div className="iconBox">

                            </div>
                            <div className="content">

                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
        // <div className="col-lg-4 col-sm-6">
        //     <div className="service-card">
        //         <img src={service.previewImg} className="img-fluid" alt={service.title} />
        //         <p>{service.category} | {service.price} </p>
        //         <div className="service-category">{service.category}</div>
        //         <div className="content">
        //             <h4>{service.title}</h4>
        //             <div className="card-icons">
        //                 <i className="fa-solid fa-plus"></i>
        //                 {/* <i className="fa-solid fa-play"></i> */}
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default ServiceCard