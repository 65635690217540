import React from 'react';
import './reservationButton.css';

function ReservationButton({icon, name, bgColor = '#ff3700', color = '#ffffff'}) {
  
  return (
    <a 
      className='mainBtn' 
      // href="https://onglesjessica.com"
      href="#"
      style={{color: color, background: bgColor}}
      target="_blank"
      rel="noopener noreferrer"
    >
      {icon} {name}
    </a>
  )
}

export default ReservationButton