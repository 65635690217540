import React, {useState, useEffect} from 'react';
import 'swiper/css';
import './homeBanner.css';
// import bgImg from '../images/golf-bg02.jpg';
// import ServiceContent from '../components/ServiceContent';
// import ServiceDate from '../components/ServiceDate';
import PlayButton from '../../components/PlayButton';
import ServiceSwiper from '../../components/ServiceSwiper';
const dataServicesUrl = process.env.REACT_APP_DATA_SERVICES_URL;

function Banner() {
    const [services, setServices] = useState([]);

    const fetchData = () => {
        // fetch('http://localhost:3000/data/servicesData.json')
        fetch(dataServicesUrl)
        .then(res => res.json())
        .then(data => setServices(data))
        .catch(e => console.log(e.message));
    };

    useEffect(() => {
        fetchData()
    }, []);

    const handleSlideChange = id => {
        const newServices = services.map(service => {
            service.active = false;
            if (service._id === id) {
                service.active = true;
            }
            return service;
        });
        setServices(newServices);
    }

    return (
        <div className='banner'>
            {
                services && services.length > 0 && services.map(service => (
                    <div className='service' key={service._id}>
                        <img src={service.bgImg} className={`bgImg ${service.active ? 'active' : undefined}`} alt={service.title} />
                        <div className='container-fluid'>
                            <div className='playButtonWrapper'>
                                <PlayButton service={service} />
                                {/* <div className='col-lg-6 col-md-12'>
                                <ServiceContent service={service} />
                                </div> */}
                                {/* <div className='col-lg-6 col-md-12'>
                                <ServiceDate service={service} />
                                <PlayButton service={service} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                ))
            }
            
            {services && services.length > 0 && (
                <ServiceSwiper slides={services} slideChange={handleSlideChange} />
            )}
        </div>
    );
}

export default Banner