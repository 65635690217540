import React from 'react';
import './homePage.css';

// import Schedule from './Schedule';
// import Trend from './Trend';
// import Blog from './Blog';
import HomeBanner from './HomeBanner';
import HomeServices from './HomeServices';
import HomeFaqs from './HomeFaqs';
import HomeContact from './HomeContact';

function HomePage() {
    return (
        <main>
            <HomeBanner />
            <HomeServices />
            <HomeFaqs />
            <HomeContact />
            {/* <Schedule />
            <Trend />
            <Blog />
            <GoogleMap /> */}
        </main>
    )
}

export default HomePage